import image from './POWER-LOGO-2.png';
import './App.css';
import {useCallback, useState} from "react";

function App() {
  const [ resText,setResText ] = useState("waiting");
  const handleClick = useCallback(async (action) => {

    const sspURL = 'https://api.dev.streetsidepower.com/action'
    const body = {
      "action": action.target.innerText.toUpperCase(),
      "deviceId": "joseph_raspberi_pi",
      "topic": "sdk/test/python"
    };
    // console.log(body)
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
      body: JSON.stringify(body)
    };
    const response = await fetch(sspURL, requestOptions);
    let myResponse = await response.json();
    console.log(myResponse);
    let newText;
    if(response.ok){
      newText = `success: ${response.status}, action: ${myResponse.action}, deviceId: ${myResponse.deviceId}`;
      setResText(newText);
      console.log("success");
    } else {
      newText = `error: ${response.status}`;
      setResText(newText);
      console.log("error");
    }
    return response;
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={image} className="App-logo" alt="logo"/>
        <div className='space'></div>
        <button className="button" onClick={handleClick}>OPEN</button>
        <div className='space'></div>
        <button className="button" onClick={handleClick}>CLOSE</button>
        <div className='space'></div>
        <textarea className='results-area' value={resText} onChange={e => setResText(e.target.value)}></textarea>
      </header>

    </div>
  );
}

export default App;
